import { createContext, useState, useEffect } from "react";
import { Box, Image, Heading } from "../elements";
import PulseLoader from "react-spinners/PulseLoader";

export const FullPageLoader = () => {
  return (
    <Box className="mc-spinner">
      <Image src="/images/VR19Logo.png" aly="logo" />
      <Box className="mc-spinner-group">
        <Heading>Loading</Heading>
        <PulseLoader color="#0857f5" loading={true} size={8} />
      </Box>
    </Box>
  );
};
