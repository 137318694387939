import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td
} from "../../../../components/elements/Table";
import { Box } from "../../../../components/elements";
import _ from "lodash";
import { Avatar, Flex, Grid } from "@chakra-ui/react";
import { formatDateHelper } from "../../../../utils/formatDate";
import { TransactionFlowEnum } from "../../../../config/enum";

const getURL = (name) => {
  return `https://api.octoelysium.com/vrNineteen/storage/images/${name}`;
};

const thead = [
  "ID",
  "Type",
  "To",
  "User",
  "Created At",
  "Amount",
  "Status",
  "Reference",
  "Comment",
  "View",
  "Action"
];
// {req: {}, status}
const DepositTable = ({ requests, onChangeStatus }) => {
  return (
    <Box className="mc-table-responsive">
      <Table className="mc-table product">
        <Thead className="mc-table-head primary">
          <Tr>
            {thead.map((item, index) => (
              <Th key={index}>{item}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody className="mc-table-body even">
          {requests?.length ? (
            <>
              {_.map(requests, (_request) => {
                //   const _request = _r?.length ? _r[0] : null

                return (
                  <Tr key={_request?.id}>
                    <Td>#{_request?.id}</Td>
                    <Td>
                      {_request?.transaction_flow ===
                      TransactionFlowEnum.WALLET_ACCOUNT
                        ? _request?.type == "WITHDRAW"
                          ? "DEPOSIT"
                          : "WITHDRAW"
                        : _request?.type}
                    </Td>
                    <Td>
                      {_request?.transaction_flow ===
                      TransactionFlowEnum.WALLET_ACCOUNT
                        ? _request?.account?.account_id
                        : "WALLET"}
                    </Td>
                    <Td>
                      <Flex
                        align="center"
                        direction={["column", "column", "row", "row"]}
                      >
                        <Avatar mr={2} name={_request?.created_by?.name} />
                        <Box>
                          <div>{_request?.created_by?.name}</div>
                          <div>{_request?.created_by?.email}</div>
                        </Box>
                      </Flex>
                    </Td>
                    <Td>{formatDateHelper(_request?.created_at)}</Td>
                    <Td>{_request?.amount}</Td>
                    <Td>{_request?.status}</Td>
                    <Td>{_request?.reference}</Td>
                    <Td>{_request?.comment}</Td>
                    <Td>
                      {_request?.image_url ? (
                        <a href={getURL(_request?.image_url)} target="_blank">
                          View
                        </a>
                      ) : null}
                    </Td>

                    <Td>
                      {_request?.status === "PENDING" ? (
                        <Grid gap={1} width="100%" maxWidth="100px">
                          <button
                            onClick={() => {
                              onChangeStatus({
                                req: { ..._request },
                                status: "accept"
                              });
                            }}
                            className="mc-btn green"
                          >
                            {" "}
                            Accept
                          </button>

                          <button
                            onClick={() => {
                              onChangeStatus({
                                req: { ..._request },
                                status: "reject"
                              });
                            }}
                            className="mc-btn red"
                          >
                            Reject
                          </button>
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </Td>
                  </Tr>
                );
              })}
            </>
          ) : (
            <Tr>
              <Td colSpan={thead?.length ?? 1}>
                <div style={{ textAlign: "center", width: "100%" }}>
                  No Data found
                </div>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  );
};

export default DepositTable;
